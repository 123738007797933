import React, { useEffect, useState, useContext } from 'react';
import { TitleStyled, MainDescription, StyledArticle } from '../../styles/pages/recover-pin';
import { Photo } from '../photo';
import creditCardService from '../../services/endpoints';
import { imageCompressor } from '../../utils/images';
import { navigateToFailed, navigateToSuccess } from '../../utils/navigate';
import pages from '../../constants/pages.json';
import { navigateToError } from '../../utils/navigate';
import { CompanyContext } from '../../context/company.context';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../context/lang.context';

const FirstStep = ({ data, display }: any) => {
  const { t } = useContext(LangContext);
  const { getPublicURL } = useContext(CompanyContext);
  return (
    <StyledArticle>
      <div className={display ? '' : 'hidden'}>
        <TitleStyled>
          {t(data).LIVENESS.TITLE_1}
          <br />
          {t(data).LIVENESS.TITLE_2}
          <br />
          <p>{t(data).LIVENESS.SUBTITLE_1}</p>
        </TitleStyled>
        <img
          src={getPublicURL(`/theme/assets/images/recover-pin/recover-pin.svg`)}
          alt={t(data).LIVENESS.IMAGE_ALT_TEXT_4}
          className="m-auto"
        />
        <TitleStyled>
          <p>{t(data).LIVENESS.SECOND_STEP.DESCRIPTION_1}</p>
        </TitleStyled>
      </div>
    </StyledArticle>
  );
};

const SecondStep = ({ data, next, display }: any) => {
  const { t } = useContext(LangContext);
  const [frontDni, setFrontDni] = useState<any>(null);
  const [backDni, setBackDni] = useState<any>(null);

  useEffect(() => {
    frontDni ? display(false) : '';
  }, [frontDni]);

  return (
    <>
      <>
        <Photo
          button={t(data).LIVENESS.SECOND_STEP.BUTTON_1}
          repeat={t(data).LIVENESS.SECOND_STEP.REPEAT}
          takePhoto={setFrontDni}
        />
      </>
      {frontDni && (
        <>
          {!backDni && <MainDescription>{t(data).LIVENESS.SECOND_STEP.DESCRIPTION_2}</MainDescription>}
          <Photo
            button={t(data).LIVENESS.SECOND_STEP.BUTTON_2}
            repeat={t(data).LIVENESS.SECOND_STEP.REPEAT}
            takePhoto={setBackDni}
            last
            nextStep={() => next([frontDni, backDni])}
          />
        </>
      )}
    </>
  );
};

export const RecoverPin = ({ token }: any) => {
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          LIVENESS {
            TITLE_1
            TITLE_2
            SUBTITLE_1
            SECOND_STEP {
              BUTTON_1
              REPEAT
              DESCRIPTION_1
              DESCRIPTION_2
              BUTTON_2
            }
          }
        }
      }
    }
  `);

  const [current, setCurrent] = useState(1);
  const [display, setDisplay] = useState(true);

  const submitDNI = async (dni: any) => {
    if (dni.length == 2) {
      const [DNIFront, DNIBack] = await Promise.all([imageCompressor(dni[0]), imageCompressor(dni[1])]);
      const urlFallback = window?.location + '';

      creditCardService
        .postDocument(
          {
            documentFront: DNIFront,
            documentBack: DNIBack,
          },
          { token: token }
        )
        .then(({ action, url }) => {
          const state = {
            from: pages.SUCCESS_PAGE.RECOVER_PIN,
            url: '',
          };
          if (action === 'redirect') {
            state.url = `/${url}`;
          }
          navigateToSuccess({ state })?.();
        })
        .catch(error => {
          console.log(error);
          
          const error_code = error?.response?.data?.details[0]?.error_code;
          if (error_code === 'FGS1200') {
            navigateToError()?.();
          } else if (error_code === 'FGS1800') {
            const state = {
              from: pages.FAILED_PAGE.RECOVER_PIN,
            };
            navigateToFailed({ state })?.();
          } else if (error_code === 'FGS1802') {
            const state = {
              from: pages.FAILED_PAGE.PHOTO_UNCLEAR,
              url: urlFallback,
            };
            navigateToFailed({ state })?.();
          } else {
            navigateToError()?.();
          }
        });
    }
  };

  return (
    <>
      {current === 1 && <FirstStep data={data} next={setCurrent} display={display} />}
      {current === 1 || current === 2 ? <SecondStep data={data} next={submitDNI} display={setDisplay} /> : null}
    </>
  );
};

export default RecoverPin;
