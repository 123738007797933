import React, { useContext, useRef, useState } from 'react';
import { PhotoProps } from './model';
import { rotateImage, toDataUrl } from '../../utils/images';
import { Images, Link, SubmitSecondStep } from '../../styles/pages/liveness';
import { StepContainerInstruction, StyledArticle, SubtitlePhoto } from '../../styles/pages/recover-pin';
import { graphql, useStaticQuery } from 'gatsby';
import { LangContext } from '../../context/lang.context';
import Button from '../button/button';

const Photo = ({ button, repeat, takePhoto, last, nextStep, noBackground, fullWidth }: PhotoProps) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          LIVENESS {
            SECOND_STEP {
              BUTTON_TEXT
              BUTTON_3
              SUBTITLE
              SUBTITLE_2
            }
          }
        }
      }
    }
  `);
  const inputRef = useRef(null);
  const [imageSrc, setImageSrc] = useState('');
  const [disable, setDisable] = useState(false);

  const onPictureCaptured = (file: any) => {
    takePhoto(file);
    toDataUrl(file).then((result: any) => {
      setImageSrc(result);
    });
  };

  const rotatePhoto = (photo: string) => {
    rotateImage(photo).then(setImageSrc);
  };

  return (
    <>
      {!imageSrc ? (
        <StyledArticle className={fullWidth && 'w-full'}>
          <Button
            color={'primary'}
            type="button"
            onClick={() => (inputRef.current as any)!.click()}
            className="mt-0 mb-6 "
          >
            {button}
          </Button>
        </StyledArticle>
      ) : last && nextStep ? (
        <>
          <StepContainerInstruction noBackground>
            <SubtitlePhoto>
              <b>{t(data).LIVENESS.SECOND_STEP.SUBTITLE_2}</b>
            </SubtitlePhoto>
            {imageSrc && <Images src={imageSrc} alt="dni" />}
            <SubmitSecondStep>
              <Link type="button" onClick={() => (inputRef.current as any)!.click()}>
                {repeat}
              </Link>
              <div className="flex flex-row text-gray-600" onClick={() => rotatePhoto(imageSrc)}>
                <p className="pr-2">{t(data).LIVENESS.SECOND_STEP.BUTTON_TEXT}</p> ↻
              </div>
            </SubmitSecondStep>
          </StepContainerInstruction>
          <StyledArticle className={`mt-8 ${fullWidth && 'w-full'}`}>
            <Button
              color={'primary'}
              className={`mt-0 mb-6 ${disable && 'bg-gray-400'}`}
              disabled={disable}
              type="button"
              onClick={() => {
                setDisable(true);
                nextStep(3);
              }}
            >
              {t(data).LIVENESS.SECOND_STEP.BUTTON_3}
            </Button>
          </StyledArticle>
        </>
      ) : (
        <>
          <StepContainerInstruction noBackground>
            <SubtitlePhoto>
              <b>{t(data).LIVENESS.SECOND_STEP.SUBTITLE}</b>
            </SubtitlePhoto>
            {imageSrc && <Images src={imageSrc} alt="dni" />}
            <SubmitSecondStep>
              <Link type="button" onClick={() => (inputRef.current as any)!.click()}>
                {repeat}
              </Link>
              <div className="flex flex-row text-gray-600" onClick={() => rotatePhoto(imageSrc)}>
                <p className="pr-2">{t(data).LIVENESS.SECOND_STEP.BUTTON_TEXT}</p> ↻
              </div>
            </SubmitSecondStep>
          </StepContainerInstruction>
        </>
      )}

      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        capture="environment"
        onChange={(event: React.ChangeEvent<HTMLInputElement> & { target: Element & { files: FileList } }) => {
          event.target.files[0] && onPictureCaptured(event.target.files[0]);
        }}
        style={{ visibility: 'hidden', height: 0 }}
      />
    </>
  );
};

export { Photo };
