import styled from '@emotion/styled';
import tw from 'twin.macro';
import { Button } from '../../../styles/common/buttons';

const TitleStyled = styled.h1`
  ${tw`text-center text-3xl text-primary-900 mb-5 mt-5 font-thin leading-tight tracking-tight`}
  b {
    ${tw`font-medium`}
  }
  & p {
    ${tw`mt-2 text-gray-600 text-center pb-3 text-base font-normal leading-6`}
  }
`;

const SubtitleStyled = styled.h1`
  ${tw`text-center text-2xl text-primary-900 mb-5 mt-5 font-bold leading-tight tracking-tight`}
  b {
    ${tw`font-medium`}
  }
  & p {
    ${tw`text-center text-lg text-gray-600 mb-2 mt-2 font-normal leading-tight tracking-tight`}
  }
`;

const StepContainerInstruction = styled.article`
  ${tw`bg-white shadow-md p-5 mb-5 rounded-md border border-gray-200 flex flex-col items-center`}
`;

const MainDescription = styled.p`
  ${tw`text-gray-600 text-left font-thin text-sm mt-2`}
  ${({ extraSmall }: any) => extraSmall && tw`text-xs`}
  b {
    ${tw`text-primary font-medium`}
  }
`;

const IconBox = styled.p`
  ${tw`text-xs text-gray-600 absolute`}

  ${({ up }: any) => up && tw`transform rotate-180`}
  right: 15px;
  top: 15px;
`;

const MainButton = styled(Button)`
  ${tw`text-white py-3 rounded-full w-11/12 mt-0 mb-6 flex justify-center font-bold`}
`;

const SubmitSecondStep = styled.article`
  ${tw`flex justify-between w-full`}
  button {
    ${tw` my-auto`}
  }
`;

const Images = styled.img`
  ${tw`my-3`}
`;

const Link = styled.button`
  ${tw`text-primary text-sm bg-transparent border-b border-primary outline-none mb-4 mr-3`}
  ${({ position }: any) =>
    (({
      center: tw`self-center`,
      left: tw`self-start`,
    } as any)[position ?? 'left'])}
`;

export {
  TitleStyled,
  StepContainerInstruction,
  MainDescription,
  IconBox,
  MainButton,
  SubmitSecondStep,
  Images,
  Link,
  SubtitleStyled,
};
