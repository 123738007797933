import Compressor from 'compressorjs';

const rotateImage = (base64data: string, degrees = 90): Promise<string> => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    canvas.setAttribute('id', 'hidden-canvas');
    canvas.style.display = 'none';
    document.body.appendChild(canvas);
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = base64data;
    image.onload = () => {
      const w = image.width;
      const h = image.height;
      const rads = (degrees * Math.PI) / 180;
      let c = Math.cos(rads);
      let s = Math.sin(rads);
      if (s < 0) {
        s = -s;
      }
      if (c < 0) {
        c = -c;
      }
      canvas.width = h * s + w * c;
      canvas.height = h * c + w * s;
      ctx?.translate(canvas.width / 2, canvas.height / 2);
      ctx?.rotate((degrees * Math.PI) / 180);
      ctx?.drawImage(image, -image.width / 2, -image.height / 2);
      resolve(canvas.toDataURL('image/jpeg'));
      document.body.removeChild(canvas);
    };
    image.onerror = () => {
      reject('Unable to rotate data\n' + image.src);
    };
  });
};

/**
 * Converts a passed File object into its Data URL representation.
 *
 * @param  {File}    file A File object, mostly retrieved from a FileList.
 * @return {Promise}      A Promise that will resolve into a Data URL string,
 *   representing the passed (image) file, or reject with the passed event
 *   object.
 */
const toDataUrl = (file: File) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = (event: ProgressEvent<FileReader>) => resolve(event.target?.result);
    fileReader.onerror = event => reject(event);
    fileReader.readAsDataURL(file);
  });

const imageCompressor = (file: File | Blob): Promise<any> => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.6,
      maxWidth: 1600,
      maxHeight: 1600,
      success(result) {
        resolve(result);
      },
      error(err) {
        reject(err);
      },
    });
  });
};

export { rotateImage, toDataUrl, imageCompressor };
