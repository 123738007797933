import styled from '@emotion/styled';
import tw from 'twin.macro';

import { Button } from '../../../styles/common/buttons';

const TitleStyled = styled.h1`
  ${tw`text-center text-3xl text-primary-900 mb-5 font-thin leading-tight tracking-tight`}

  & p {
    ${tw`mt-2 text-gray-600 text-center text-base font-normal leading-6`}
  }
`;

const Images = styled.img`
  ${tw`my-3`}
`;

const Link = styled.button`
  ${tw`text-primary text-sm bg-transparent border-b border-primary outline-none mb-4 mr-3`}
  ${({ position }: any) =>
    (({
      center: tw`self-center`,
      left: tw`self-start`,
    } as any)[position ?? 'left'])}
`;

const RecoverPinButton = styled(Button)`
  ${tw`text-white py-3 rounded-full w-11/12 mt-0 mb-6 flex justify-center font-bold`}
`;

const ErrorTitleStyled = styled.h1`
  ${tw`text-center text-4xl text-red font-thin leading-10 mb-2`}

  b {
    ${tw`font-bold`}
  }
`;

const SuccessTitleStyled = styled.h1`
  ${tw`text-center text-4xl text-primary-900 leading-10 mb-6`}

  b {
    ${tw`font-bold`}
  }
`;

const MainDescription = styled.p`
  ${tw`text-gray-600 text-center font-thin text-sm mt-12 mb-8 px-8`}
  b {
    ${tw`text-primary font-medium`}
  }
`;

const StyledArticle = styled.article`
  ${tw`flex flex-col items-center`}
`;

const StepContainerInstruction = styled.article`
${({ noBackground }: any) => noBackground ? tw`mb-4` : tw`bg-white shadow-md p-5 rounded-md border border-gray-200 flex flex-col items-center`}
`;

const SubtitlePhoto = styled.p`
  ${tw`text-gray-600 text-left font-thin text-sm mr-auto`}
  b {
    ${tw`text-primary font-medium`}
  }
`;

export {
  TitleStyled,
  Images,
  Link,
  RecoverPinButton,
  ErrorTitleStyled,
  SuccessTitleStyled,
  MainDescription,
  StepContainerInstruction,
  StyledArticle,
  SubtitlePhoto,
};
