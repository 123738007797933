import { css } from '@emotion/react';
import styled from '@emotion/styled';
import tw from 'twin.macro';

import { twButtonColor } from './colors';

const Button = styled.button`
  ${tw`text-white py-3 rounded-full w-4/6 mt-12 mb-6`}
  ${css`
    ${twButtonColor}
  `}
`;

const ButtonRetry = styled.button`
  ${tw`text-white py-3 rounded-full w-5/6 mt-2 mb-1 font-bold`}
  ${css`
    ${twButtonColor}
  `}
`;

const ButtonBackWsp = styled.button`
  ${tw`text-primary py-3 rounded-full w-5/6 mt-2 mb-10 border-2 border-primary bg-white font-bold`}
`;

export { Button, ButtonRetry, ButtonBackWsp };
