import React, { useEffect, useContext } from 'react';
import Layout from '../../components/responsive/layout';
import Topbar from '../../components/responsive/topbar';
import { RecoverPin } from '../../components/upload-document/index';
import { LoadingComponent } from '../../components/loading';
import pages from '../../constants/pages.json';
import { navigateToError, redirectWhenStatusPageIsNotValid } from '../../utils/navigate';
import { CreditcardContext } from '../../context/creditcard.context';
import { diffTwoDatesInSeconds as diff, isTrue, isGreaterThan } from '../../utils/functions';
const RenderLayout = ({ render, get, token }: any) => {
  if (!render) {
    return <LoadingComponent />;
  }

  return (
    <Layout>
      <Topbar />
      <div className="content">
        <RecoverPin get={get} token={token} />
      </div>
    </Layout>
  );
};

export const RecoverPinPage = ({ get, getToken }: PageProps) => {
  const { infoUser, timeStamp, getUser } = useContext(CreditcardContext);
  const allValid = [Object.entries(infoUser).length, timeStamp?.length ?? 0].every(isGreaterThan(0));
  const token: string = getToken();

  useEffect(() => {
    if (!token) {
      navigateToError()?.();
    } else if (!infoUser?.expirationTime) {
      getUser?.(token);
    }
  }, []);
  useEffect(() => {
    if (allValid) {
      const state = { expired: { state: { messageMiddleFrom: pages.USER.UPDATE_USER_DETAIL } } };
      redirectWhenStatusPageIsNotValid(infoUser, timeStamp, state);
    }
  }, [infoUser, timeStamp]);

  const render = [allValid, !!diff(timeStamp ?? '', infoUser?.expirationTime ?? ''), !infoUser.status].every(isTrue);
  return <RenderLayout render={render} get={get} token={token} />;
};

export default RecoverPinPage;
